<template>
  <router-layout>
    <div class="page">
      <div class="header">
        <h2>{{title}}</h2>
        <div class="timeBox">
          <div>
            <span class="iconfont icon-shizhong"></span>
            <span>{{create_time}}</span>
          </div>
          <div class="like">
            <van-checkbox v-model="favorite" @click="favoriteChange">
              <template #icon="props">
                <van-icon name="like" color="#f03535" v-if="props.checked" />
                <van-icon name="like-o" v-else />
              </template>
            </van-checkbox>
            <div v-if="favorite">已收藏</div>
            <div v-else>收藏</div>
          </div>
        </div>
      </div>
      <div class="line"></div>
      <div class="body">
        <ul>
          <li>
            有效期:
            <span>{{valid_time}}</span>
          </li>
          <li>
            性别要求:
            <span>{{sex}}</span>
          </li>
          <li>
            月薪:
            <span>{{money}}</span>
          </li>
          <li>
            职位:
            <span>{{position}}</span>
          </li>
          <li>
            学历要求:
            <span>{{education}}</span>
          </li>
          <li>
            工作年限:
            <span>{{work_years}}</span>
          </li>
          <li>
            公司名称:
            <span>{{company}}</span>
          </li>
          <li>
            公司性质:
            <span>{{company_type}}</span>
          </li>
          <li class="fl">
            <div class="spanfl">
              公司福利:
            </div>
            <div class="spanfr">
              <span v-show="insurance.value">{{insurance.text}}</span>
              <span v-show="live.value">{{live.text}}</span>
              <span v-show="eat.value">{{eat.text}}</span>
              <span v-show="double.value">{{double.text}}</span>
              <span v-show="weekend.value">{{weekend.text}}</span>
              <span v-show="traffic.value">{{traffic.text}}</span>
              <span v-show="overtime.value">{{overtime.text}}</span>
              <span v-show="meal.value">{{meal.text}}</span>
              <span v-show="tel.value">{{tel.text}}</span>
              <span v-show="house.value">{{house.text}}</span>
            </div>
          </li>
          <li>
            联系人:
            <span>
              {{name}}
              <em>
                <a :href="'tel:'+ phone">{{phone}}</a>
              </em>(点击号码拨打)
            </span>
          </li>
          <li v-show="hasImg">图片:</li>
          <li class="img" v-show="hasImg">
            <img :src="item" v-for="item,index in image" :key="index" @click="imageView(index)" />
          </li>
        </ul>
      </div>
      <div class="dr">工作需求</div>
      <div class="detail" v-html="description"></div>
    </div>
  </router-layout>
</template>
<script>
import authMixin from "@/mixin/mixin";
import { ImagePreview } from "vant";
export default {
  data() {
    return {
      infoType:'',
      id: "",
      title: "",
      create_time: "",
      name: "",
      phone: "",
      valid_time: "",
      description: "",
      favorite: false,  //收藏
      image: [],
      sex: "",
      money: "",
      position: "",
      education: "",
      work_years: "",
      company: "",
      company_type: "",
      hasImg:false,
      insurance: {
        text: "五险一金",
        value: 0
      },
      live: {
        text: "包住",
        value: 0
      },
      eat: {
        text: "包吃",
        value: 0
      },
      double: {
        text: "年底双薪",
        value: 0
      },
      weekend: {
        text: "周末双休",
        value: 0
      },
      traffic: {
        text: "交通补助",
        value: 0
      },
      overtime: {
        text: "加班补助",
        value: 0
      },
      meal: {
        text: "餐补",
        value: 0
      },
      tel: {
        text: "话补",
        value: 0
      },
      house: {
        text: "房补",
        value: 0
      }
    };
  },
  mixins: [authMixin],
  created() {
    this.id = this.$route.query.id;
    this.infoType = this.$route.query.infoType
    this.getDetail();
    this.getHistory()
  },
  methods: {
    getHistory() {
      //我的历史
      this.$request({
        method: "post",
        url: "/cms/history/",
        data: {
          type: this.infoType,
          fid: this.id
        }
      }).then(res => {
        console.log(res);
      });
    },
    favoriteChange() {
      if (!this.favorite) {
        console.log('去取消')
        //取消收藏
        this.$request({
          method: "post",
          url: "/cms/deleteFavorite/",
          data: {
            type: this.infoType,
            fid: this.id
          }
        }).then(res => {
          console.log(res);
        });
      } else {
        //收藏
        console.log('去收藏')
        this.$request({
          method: "post",
          url: "/cms/favorite/",
          data: {
            type: this.infoType,
            fid: this.id
          }
        }).then(res => {
          console.log(res);
        });
      }
    },
    imageView(index) {
      let _this = this;
      ImagePreview({
        images: this.image,
        startPosition: index
      });
    },
    getDetail() {
      this.$request({
        method: "get",
        url: "cms/recruit/detail/" + this.id
      }).then(res => {
        console.log(res);
        if (res.data) {
          this.create_time = res.data.create_time;
          this.title = res.data.title;
          this.name = res.data.name;
          this.phone = res.data.phone;
          this.description = res.data.description;
          this.position = res.data.position;
          this.company = res.data.company;
          this.favorite = res.data.favorite;
          if (res.data.imgs) {
            this.image = res.data.imgs.split(",");
            this.hasImg = true
          }else{
            this.hasImg = false
          }
          if (res.data.insurance) {
            this.insurance.value = 1;
          }
          if (res.data.live) {
            this.live.value = 1;
          }
          if (res.data.eat) {
            this.eat.value = 1;
          }
          if (res.data.double) {
            this.double.value = 1;
          }
          if (res.data.weekend) {
            this.weekend.value = 1;
          }
          if (res.data.traffic) {
            this.traffic.value = 1;
          }
          if (res.data.overtime) {
            this.overtime.value = 1;
          }
          if (res.data.meal) {
            this.meal.value = 1;
          }
          if (res.data.tel) {
            this.tel.value = 1;
          }
          if (res.data.house) {
            this.house.value = 1;
          }
          switch (res.data.company_type) {
            case 1:
              this.company_type = "私营";
              break;
            case 2:
              this.company_type = "国有";
              break;
            case 3:
              this.company_type = "股份制";
              break;
            case 4:
              this.company_type = "外商独资办事处";
              break;
            case 5:
              this.company_type = "中外合资/合作";
              break;
            case 6:
              this.company_type = "上市公司";
              break;
            case 7:
              this.company_type = "事业单位";
              break;
            case 8:
              this.company_type = "政府机关";
              break;
          }
          switch (res.data.money) {
            case 0:
              this.money = "面议";
              break;
            case 1:
              this.money = "1000以下";
              break;
            case 2:
              this.money = "1000~2000";
              break;
            case 3:
              this.money = "2000~6000";
              break;
            case 4:
              this.money = "6000~8000";
              break;
            case 5:
              this.money = "8000~12000";
              break;
            case 6:
              this.money = "12000~30000";
              break;
            case 7:
              this.money = "30000以上";
              break;
          }
          switch (res.data.work_years) {
            case 0:
              this.work_years = "不限";
              break;
            case 1:
              this.work_years = "1-2年";
              break;
            case 2:
              this.work_years = "3-5年";
              break;
            case 3:
              this.work_years = "6-7年";
              break;
            case 4:
              this.work_years = "8-10年";
              break;
            case 5:
              this.work_years = "10年以上";
              break;
          }
          switch (res.data.education) {
            case 1:
              this.education = "初中及以下";
              break;
            case 2:
              this.education = "高中/中专/技校";
              break;
            case 3:
              this.education = "大专";
              break;
            case 4:
              this.education = "本科";
              break;
            case 5:
              this.education = "硕士";
              break;
            case 6:
              this.education = "博士及以上";
              break;
          }
          switch (res.data.sex) {
            case 1:
              this.sex = "男";
              break;
            case 2:
              this.sex = "女";
              break;
          }
          switch (res.data.valid_time) {
            case 0:
              this.valid_time = "长期有效";
              break;
            case 1:
              this.valid_time = "一周";
              break;
            case 2:
              this.valid_time = "一个月";
              break;
            case 3:
              this.valid_time = "两个月";
              break;
            case 4:
              this.valid_time = "一年";
              break;
          }
        }
      });
    }
    //method end
  }
};
</script>
<style scoped lang="scss">
@import "../../../assets/iconfont/iconfont.css";
.page {
  background: #fff;
  min-height: 100vh;
  padding-bottom: 1.5rem;
  .header {
    padding: 0.55rem 0.3rem;
    border-bottom: 1px solid #eee;
    h2 {
      font-weight: normal;
      font-size: 0.42rem;
      margin-bottom: 0.5rem;
    }
    .timeBox {
      display: flex;
      justify-content: space-between;
      color: #999;
      font-size: 0.34rem;
      height: 0.5rem;
      align-items: center;
      .iconfont {
        margin-right: 0.1rem;
      }
      .like {
        display: flex;
        align-items: center;
        .van-icon {
          font-size: 0.5rem;
          border: none;
          color: #ccc;
          margin-left: 0.05rem;
        }
        .van-checkbox__icon--checked .van-icon {
          background: transparent;
        }
      }
    }
  }
  .line {
    height: 0.2rem;
    background: #f5f5f5;
  }
  .body {
    padding: 0.8rem 0.3rem 0.6rem 0.3rem;
    li.fl{
      height: auto;
      overflow: hidden;
    }
    li {
      color: #9e9e9e;
      margin-bottom: 0.26rem;
      font-size: 0.38rem;
      .spanfl{
        float:left;
        line-height: .7rem;
      }
      .spanfr{
        margin-left: 1.8rem;
        line-height: .7rem;
      }
      span {
        margin-left: 0.2rem;
        color: #333;
        font-size: 0.38rem;
        a {
          color: red;
          font-style: normal;
          font-size: 0.4rem;
          vertical-align: -0.03rem;
          margin-right: 0.2rem;
        }
      }
      img {
        display: inline-block;
        width: 3.8rem;
        margin-right: 0.25rem;
      }
      .telphone {
        display: block;
        background: #567cf3;
        text-align: center;
        line-height: 0.8rem;
        width: 2.8rem;
        border-radius: 0.8rem;
        color: #fff;
      }
    }
  }
  .dr {
    background: #f5f5f5;
    color: #777;
    padding: 0.3rem 0.3rem;
    font-size: 0.38rem;
  }
  .detail {
    padding: 0.5rem 0.3rem;
    font-size: 0.38rem;
    line-height: .6rem;
  }
}
</style>